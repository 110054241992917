import React, { FunctionComponent as FC } from "react";

interface LogoProps {
  children?: React.ReactElement;
}

const Logo: FC<LogoProps> = (props: LogoProps) => {
  return (
    <div className="p-5 pl-0">
      <span className="uppercase font-semibold tracking-tight">chaguan</span>
    </div>
  );
};

export default Logo;
